<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="用户ID">
      <el-input v-model="selectModel.userId" />
    </el-form-item>
    <el-form-item label="学校">
      <el-select v-model="selectModel.collegeId" placeholder="请选择" clearable>
        <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
      </el-select>
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="用户ID" prop="userId" min-width="100px" sortable="custom" />
    <el-table-column label="学校名称" prop="collegeName" min-width="200px" />
    <el-table-column label="余额" prop="amount" min-width="200px" />
    <el-table-column label="赠金" prop="give" min-width="200px" />
    <el-table-column
      fixed="right"
      label="操作"
      width="120">
      <template #default="scope">
        <!-- <el-button @click="showDialogForm({data: scope.row, readonly: true})" type="text" size="small">查看</el-button> -->
        <el-button @click="showDetailDialog(scope.row)" type="text" size="small">明细</el-button>
        <!-- <el-button @click="showDialogForm({data: scope.row})" type="text" size="small">编辑</el-button> -->
        <!-- <el-button @click="deleteAciton(scope.row)" type="text" size="small">删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item
        label="名称" prop="name"
        :rules="[
          { required: true, message: '名称不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.name" :readonly="dialog.readonly" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog custom-class="main-el-dialog" title="明细" v-model="detailDialog.visibled" width="600px">
    <el-table :data="detailDialog.data" style="width: 100%;">
      <el-table-column label="时间" prop="createTime" />
      <el-table-column label="余额" prop="amount" />
      <el-table-column label="赠金" prop="give" />
    </el-table>
    <el-pagination
      style="margin-top: 10px; text-align: right;"
      layout="total, prev, next, jumper"
      :total="detailDialog.total"
      @current-change="applyDetailDialogPage" />
  </el-dialog>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      detailDialog: {
        visibled: false,
        row: {},
        data: [],
        current: 1,
        total: 0
      },
      colleges: []
    };
  },
  created() {
    this.$api.getCollegeMap().then(data => {
      this.colleges = data;
    });
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getPayCardPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    showDialogForm({data, created = false, readonly = false}) {
      this.dialog.readonly = readonly;
      this.dialog.created = created;
      this.dialog.visibled = true;
      if (data) {
        this.dialog.form = { ...data };
      }

      let submit;
      if (created) {
        this.dialog.title = '创建';
        this.dialog.form = {};
        submit = () => this.$api.createCollege(this.dialog.form);
      } else {
        this.dialog.title = '修改';
        submit = () => this.$api.updateCollege(this.dialog.form);
      }

      if (readonly) {
        this.dialog.title = '详情';
        submit = () => false;
      }

      this.dialog.submit = () => this.validateForm(() => submit()
        .then(() => {
          this.$message.success('操作成功');
          this.dialog.visibled = false;
          this.queryTableData();
        })
        .catch(handleCatchStatus));
    },
    validateForm(successFn) {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          successFn();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dialogForm.resetFields();
    },
    deleteAciton(data) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.deleteCollege(data.id))
        .then(() => {
          this.$message.success('删除成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消删除'));
    },
    showDetailDialog(data) {
      this.detailDialog.visibled = true;
      this.detailDialog.row = data;
      this.detailDialog.current = 1;
      this.queryDetail();
    },
    queryDetail() {
      this.$api.getPayOrderLocalPage({
        cardId: this.detailDialog.row.id,
        page: this.detailDialog.current - 1
      }).then(resp => {
        this.detailDialog.data = resp.content;
        this.detailDialog.total = resp.totalElements;
      });
    },
    applyDetailDialogPage(current) {
      this.detailDialog.current = current;
      this.queryDetail();
    }
  }
};
</script>

<style lang="scss" scoped>
  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
</style>
